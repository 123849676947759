<template>
  <v-container>
    <loader-view />

    <ProofDialog ref="proofDialog" :lockClose="true" @success="submit()" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { joinTeamCode } from "@/services/teams";
import { displayAlert } from "@/utils";
import ProofDialog from "@/components/leagues/ProofDialog.vue";

export default {
  components: {
    ProofDialog,
  },

  beforeMount() {
    if (!this.$route.params.code) this.$router.push("/main");
  },

  mounted() {
    if (!this.localUser.schoolCertificate)
      this.$refs.proofDialog.handleDialog();
    else this.submit();
  },

  computed: {
    ...mapState(["localUser"]),
  },

  methods: {
    async submit() {
      try {
        const payload = {
          code: this.$route.params.code,
        };

        await joinTeamCode(payload).then((res) => {
          this.displayAlert("Você agora faz parte da equipe");
          this.$router.push({ path: `/team-details/${res.id}` });
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
        this.$router.push({ path: "/main" });
      }
    },

    displayAlert,
  },
};
</script>

<style scoped></style>

<template>
  <custom-dialog
    ref="dialog"
    title="Comprovante de matricula"
    :persistent="true"
    :lockClose="lockClose"
    @submit="submit()"
  >
    <div
      class="d-flex flex-column justify-center align-center text-center mb-8"
    >
      <div class="text-body-1 font-weight-bold mb-8">
        Você está prestes a se inscrever em uma liga
      </div>

      <div class="text-h6">
        <span class="saira">
          <span class="primary--text">ATENÇÃO:</span> Mas antes, você deve
          inserir seu comprovante escolar de matrícula.
        </span>
      </div>
    </div>

    <v-card outlined @click="onButtonClick()">
      <v-sheet color="accent">
        <div class="d-flex align-center justify-center py-8 text-body-1">
          <span v-if="form.file">{{ form.file.name }}</span>
          <span v-else>Anexar comprovante de matrícula</span>
        </div>
      </v-sheet>
    </v-card>

    <input
      accept="image/jpeg,image/png,application/pdf,image/x-eps"
      ref="uploader"
      class="d-none"
      type="file"
      @change="onFileChanged($event)"
    />

    <loader-hover v-if="loading" />
  </custom-dialog>
</template>

<script>
import { mapState } from "vuex";
import { changeProof } from "@/services/user.js";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      form: {
        file: null,
      },
    };
  },

  props: {
    lockClose: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(["user", "localUser"]),
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        const formData = new FormData();
        formData.append("file", this.form.file);

        await changeProof(formData).then(() => {
          this.$root.$emit("refresh-user");
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    onButtonClick() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      const file = e.target.files[0];
      this.form.file = file;

      this.$emit("input", file);
    },

    async handleDialog() {
      await this.$refs.dialog.openDialog();
    },

    close() {
      this.$refs.dialog.closeDialog();
    },

    displayAlert,
  },
};
</script>

<style></style>
